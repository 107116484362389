import { useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";

const Back = () => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-none hover:cursor-pointer"
      onClick={() => {
        navigate(-1);
      }}
    >
      <HiArrowNarrowLeft className="text-lg mr-1" />
    </div>
  );
};

export default Back;
