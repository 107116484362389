import * as Yup from "yup";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { stringValidation } from "../../providers/validations";
import Field from "../../components/Field";
import { FileUpload, Select, Text } from "../../inputs";
import { Form } from "../../template";

import objectPath from "object-path";
import { DATE_FORMAT_VIEW, PHOTO_ROOT, SYSTEMS } from "../../defines";
import moment from "moment";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  useMapEvents,
} from "react-leaflet";

const UserForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [position, setPosition] = useState(null);
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setPosition(e?.latlng);
        map.flyTo(e?.latlng, map.getZoom());
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>Цэгийн байршил</Popup>
      </Marker>
    );
  };
  const props = {
    editable: true,
    isDeletable: false,
    editInvoice: false,
    model: "invoice",
    text: id ? "Бүртгэл" : "Бүртгэл",
    id: id,
    init: {},
    validationSchema: Yup.object().shape({
      plate: stringValidation(true),
    }),
    setPosition: setPosition,
  };
  const beforeSubmit = async (data) => {
    return data;
  };
  return (
    <Form {...props} beforeSubmit={beforeSubmit}>
      {({ submited, disabled, values }) => {
        const isSent = objectPath.get(values, "isSent");
        const created = objectPath.get(values, "created");
        const isUB = objectPath.get(values, "isUB");
        const hasError = objectPath.get(values, "hasError");
        const timestamp = objectPath.get(values, "timestamp");
        const name = objectPath.get(values, "name");
        const mark = objectPath.get(values, "mark");
        const model = objectPath.get(values, "model");
        const cabin = objectPath.get(values, "cabin");
        const road = objectPath.get(values, "road");
        const number = objectPath.get(values, "number");
        const system = objectPath.get(values, "system");
        const is_paid = objectPath.get(values, "is_paid");
        const description = objectPath.get(values, "description");
        const amount = objectPath.get(values, "amount");

        const date = new Date(timestamp * 1000);

        const full_photo =
          PHOTO_ROOT + "/" + objectPath.get(values, "photos.0.id");

        const segment_photo =
          PHOTO_ROOT + "/" + objectPath.get(values, "photos.1.id");

        return (
          <div className="p-5 bg-white  grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <img
                src={full_photo}
                style={{
                  height: 220,
                  width: "100%",
                  borderRadius: 8,
                }}
                crossOrigin="anonymous"
              />
              <img
                src={segment_photo}
                style={{
                  height: 200,
                  width: "100%",
                  borderRadius: 8,
                }}
                className="object-fill"
                crossOrigin="anonymous"
              />
              <Field
                name="plate"
                label={`Улсын дугаар`}
                disabled={disabled}
                component={Text}
                type="text"
                className={`text-black `}
              />
              <div className="">
                <div>{`Арлын дугаар`}</div>
                <div className="p-4 bg-gray-100 border rounded">{cabin}</div>
              </div>
              <div className="">
                <div>{`Марк / Модел`}</div>
                <div className="p-4 bg-gray-100 border rounded">{`${mark} / ${model}`}</div>
              </div>

              <div className="">
                <div>{`Танилт`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {hasError ? "Алдаатай" : "Алдаагүй"}
                </div>
              </div>
              <div className="">
                <div>{`Систем`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {objectPath.get(SYSTEMS, `${system}`)}
                </div>
              </div>
              <div className="">
                <div>{`Уншигдсан огноо`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {moment(timestamp).format("yyyy-MM-DD HH:mm:ss")}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              {position && (
                <MapContainer
                  center={[
                    objectPath.get(position, "lat"),
                    objectPath.get(position, "lng"),
                  ]}
                  zoom={15}
                  style={{ width: "100%", height: 430 }}
                  scrollWheelZoom={true}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <LocationMarker />
                </MapContainer>
              )}

              <div className="">
                <div>{`Харьяа`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {isUB ? "Нийслэл" : "Орон нутаг"}
                </div>
              </div>
              <div className="">
                <div>{`Пост - Зам`}</div>
                <div className="p-4 bg-gray-100 border rounded">{`${name} - ${road}`}</div>
              </div>
              <div className="">
                <div>{`Нэхэмжлэхийн №`}</div>
                <div className="p-4 bg-gray-100 border rounded">{number}</div>
              </div>
              <div className="">
                <div>{`Нэхэмжлэхийн огноо`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {moment(created).format(DATE_FORMAT_VIEW)}
                </div>
              </div>
              <div className="">
                <div>{`Дүн`}</div>
                <div className="p-4 bg-gray-100 border rounded">{amount}</div>
              </div>
              <div className="">
                <div>{`Тайлбар`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {description}
                </div>
              </div>
              <div className="">
                <div>{`Төлөгдсөн эсэх`}</div>
                <div className="p-4 bg-gray-100 border rounded">
                  {is_paid ? "Тийм" : "Үгүй"}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default UserForm;
